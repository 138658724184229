import { FC } from 'react';
import { Box, Flex, Text } from 'theme-ui';
import ImgLoader from '../Generic/ImgLoader';
import { TransformedBundle, TransformedKit } from '~/types/filter';
import ProductLink from '../Product/Link';

type Props = {
  bundle: TransformedKit | TransformedBundle;
};
const ShopAllBundleCard: FC<Props> = ({ bundle }) => {
  const isOnSale = (bundle.regularPrice ?? 0) - (bundle.price ?? 0) !== 0;
  const { badge } = bundle;
  return (
    <ProductLink
      collectionSlug={bundle.collection?.[0]?.slug}
      productSlug={bundle.slug}
      productName={bundle.name}
      locationDetail={'Shop All'}
      sx={{
        maxWidth: ['100%', '500px'],
        gridColumn: ['span 2', 'auto'],
        position: 'relative',
        isolation: 'isolate',
      }}
    >
      <Box
        sx={{
          mb: ['.8rem', '1.6rem'],
        }}
      >
        {bundle.mainImage && (
          <ImgLoader badge={badge} image={bundle.mainImage} forwardSx={{ mb: ['1.6rem'], aspectRatio: '1/1' }} />
        )}

        <Flex sx={{ justifyContent: 'space-between', alignItems: 'flex-start', gap: '1.6rem' }}>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text variant="text.h7" sx={{ lineBreak: 'auto' }}>
              {bundle.name}
            </Text>
            <Flex
              sx={{
                alignItems: 'top',
                justifyContent: 'space-between',
                gap: '1.6rem',
              }}
            >
              {bundle.shortDescription && (
                <Text variant="text.paragraphMain" dangerouslySetInnerHTML={{ __html: bundle.shortDescription }} />
              )}
            </Flex>
            {Boolean(bundle.microloadHighlight) && (
              <Text variant="text.paragraphMain">{bundle.microloadHighlight?.replace(/microload/i, '')}</Text>
            )}
          </Flex>
          <Flex sx={{ flexDirection: 'column' }}>
            <Text variant="text.paragraphLarge" sx={{ whiteSpace: 'nowrap', flex: '0 0 auto', lineHeight: 1 }}>
              $ {bundle.price}
            </Text>
            {isOnSale && (
              <Text
                variant="text.paragraphMain"
                sx={{
                  textDecoration: 'line-through',
                  whiteSpace: 'nowrap',
                  color: 'UIGray2',
                  flex: '0 0 auto',
                }}
              >
                $ {bundle.regularPrice}
              </Text>
            )}
          </Flex>
        </Flex>
      </Box>
    </ProductLink>
  );
};

export default ShopAllBundleCard;
